import * as React from "react";
import axios from "axios";
import styled from "@emotion/styled";
import queryString from "query-string";
import { Input } from "../lib/components/Input";
import { useConfig } from "@fitplan/context/lib-es/config";
import { useAuthContext } from "@fitplan/context/lib-es/auth";
import { Polygon } from "../lib/components/Chevron/Polygon";
import { PageTitle } from "../lib/components/Header/PageTitle";
import { BrandButton } from "../lib/components/Button/BrandButton";
import { LoginForm, LoginFooter } from "../lib/components/UserAuth/LoginForm";
import { PageSubTitle } from "../lib/components/Header/PageSubTitle";
import {
  SignUpFooter,
  SignUpForm,
} from "../lib/components/UserAuth/SignUpForm";
import { LoginFormStrings } from "../components/strings/LoginFormStrings";
import { SignupFormStrings } from "../components/strings/SignupFormStrings";
import useMount from "react-use/lib/useMount";
import { graphql } from "gatsby";
import Layout from "../components/layout/layout";
import { useTranslation } from "gatsby-plugin-react-i18next";

export const query = graphql`
  query ($languageISO: String!, $language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    header: contentfulModules(
      type: { eq: "Header" }
      node_locale: { eq: $languageISO }
    ) {
      id
      items {
        ... on ContentfulItems {
          id
          link
          linkText
        }
      }
    }
    ribbon: contentfulModules(
      type: { eq: "Ribbon" }
      node_locale: { eq: $languageISO }
    ) {
      id
      heading
    }
    footer: allContentfulModules(
      filter: { type: { eq: "Footer" }, node_locale: { eq: $languageISO } }
    ) {
      nodes {
        id
        heading
        items {
          ... on ContentfulItems {
            id
            link
            linkText
          }
        }
      }
    }
  }
`;

const Login = ({ data: { ribbon, header, footer } }) => {
  const { t } = useTranslation();
  const { access_token } = useAuthContext();
  const [completed, setCompleted] = React.useState(false);
  const [isLogin, setIsLogin] = React.useState(true);
  const [codeIsSet, setCodeIsSet] = React.useState(false);
  const [code, setCode] = React.useState("");
  const [error, setError] = React.useState("");
  const submit = React.useRef();
  React.useEffect(() => {
    if (typeof window === "undefined") return;
    const query = queryString.parse(window.location.search);
    if (query && query.code) {
      const code = typeof query.code === "string" ? query.code : query.code[0];
      setCode(code);
      setCodeIsSet(true);
    }
  }, []);

  return (
    <Layout
      header={header}
      ribbon={ribbon}
      footer={footer}
      hasBanner="no-banner"
    >
      <PageTitle>{t("signInToYourTv")}</PageTitle>
      {completed ? (
        <SubTitle>{t("yourTvShouldSignIn")}</SubTitle>
      ) : !codeIsSet || error ? (
        <form
          onSubmit={async (event) => {
            event.preventDefault();
            if (code.length < 6) {
              setError(t("codeMustBeAtleast"));
            } else {
              setCodeIsSet(true);
            }
          }}
        >
          <Code
            type="string"
            value={code}
            placeholder={t("tvLoginCode")}
            onChange={(event) => {
              setCode(event.target.value);
            }}
          />
          {error && <Error>{error}</Error>}
          <Submit ref={submit} disabled={code.length < 6} type="submit">
            {t("submit")}
          </Submit>
        </form>
      ) : isLogin ? (
        <LoginFormStrings>
          <LoginForm onComplete={() => {}} />
          <LoginFooter onClick={() => setIsLogin(false)} />
        </LoginFormStrings>
      ) : (
        <SignupFormStrings>
          <SignUpForm onComplete={() => {}} />
          <SignUpFooter onClick={() => setIsLogin(true)} />
        </SignupFormStrings>
      )}
      {access_token && codeIsSet && (
        <Submitter
          code={code}
          setError={setError}
          setCompleted={setCompleted}
        />
      )}
      <Polygon polygonColor="#111213" borderColor="#00ffb3" />
    </Layout>
  );
};

const Submitter = ({ code, setError, setCompleted }) => {
  const { apiBaseUrl } = useConfig();
  const { access_token } = useAuthContext();
  useMount(async () => {
    try {
      const result = await axios({
        method: "POST",
        url: `${apiBaseUrl}/v1/television/tizen/login?code=${code.toUpperCase()}`,
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      });
      if (result.data.error) {
        setError(result.data.error);
      } else {
        setCompleted(true);
      }
    } catch (err) {
      setError(err.message);
    }
  });
  return <span />;
};

const SubTitle = styled(PageSubTitle)`
  margin-top: 24px;
`;

const Code = styled(Input)`
  flex: 0 0 auto;
  width: 100%;
  max-width: 400px;
  margin: auto;

  border-radius: 0;
`;

const Error = styled.p`
  color: #f10364;
  margin-top: 8px;

  text-align: center;
`;

const Submit = styled(BrandButton)`
  flex: 0 0 auto;

  width: 200px;
  line-height: 24px;
  margin: auto;
  margin-top: 16px;
`;

export default Login;
