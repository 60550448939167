import styled from "@emotion/styled";

export const PageTitle = styled.h1`
  font-family: Barlow Condensed, sans-serif;
  font-size: 28px;
  font-weight: bold;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1.25;
  letter-spacing: normal;
  color: black;
  padding: 0px;
  margin-top: 72px;
  margin-bottom: 40px;
  text-align: center;

  @media (min-width: 600px) {
    font-size: 44px;
  }
`;
