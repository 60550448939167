import * as React from "react";
import { StringsProvider } from "@fitplan/context/lib-es/strings";
import deepmerge from "deepmerge";

import { Props } from "./common";

export const SignupFormStrings: React.FunctionComponent<Props> = ({
  children,
  data = {},
}) => {
  const query = {
    signUpPage: {
      facebookButton: "Sign Up with Facebook",
      googleButton: "Sign Up with Google",
      firstNamePlaceholder: "First Name",
      lastNamePlaceholder: "Last Name",
      emailPlaceholder: "Email",
      passwordPlaceholder: "Password",
      repeatPasswordPlaceholder: "Repeat Password",
      radioMaleText: "Male",
      radioFemaleText: "Female",
      radioOtherText: "Other",
      spearatorText: "or sign up with email",
      title: "Create Your Account",
      footerText: "Already have an account?",
      loginLinkText: "Login",
      loginButton: "Sign Up",
      unknownError: "An unknown error occured. Please try again later.",
      facebookError:
        "An error occurred with Facebook sign up. Please try again later.",
      googleError:
        "An error occurred with Google sign up. Please try again later.",
      signUpError: "Email is already signed up",
      emailCheckbox: "Receive Fitplan updates and promotions by email",
      termsOfService: "Terms of Service",
      privacyPolicy: "Privacy Policy",
      accountAgreement: "By creating your account, you agree to Fitplan's",
    },
  };
  const strings = React.useMemo(() => deepmerge(query, data), [query, data]);
  return <StringsProvider data={strings}>{children}</StringsProvider>;
};
