import * as React from "react";
import { StringsProvider } from "@fitplan/context/lib-es/strings";
import deepmerge from "deepmerge";

import { Props } from "./common";

export const LoginFormStrings: React.FunctionComponent<Props> = ({
  children,
  data = {},
}) => {
  const query = {
    loginPage: {
      facebookButton: "Login with Facebook",
      googleButton: "Login with Google",
      emailPlaceholder: "Email",
      passwordPlaceholder: "Password",
      spearatorText: "or login with email",
      title: "Welcome Back",
      footerText: "Don't have an account?",
      signUpLinkText: "Sign Up",
      loginButton: "Login",
      unknownError: "An unknown error occured. Please try again later.",
      facebookError:
        "An error occurred with Facebook login. Please try again later.",
      googleError:
        "An error occurred with Google login. Please try again later.",
      loginError: "Invalid username or password",
      emailError: "Please enter a valid email address",
      passwordError: "Please enter a valid password",
      forgotPasswordLinkText: "Forgot your password?",
    },
  };
  const strings = React.useMemo(() => deepmerge(query, data), [query, data]);
  return <StringsProvider data={strings}>{children}</StringsProvider>;
};
