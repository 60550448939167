import styled from "@emotion/styled";
import { PageTitle } from "./PageTitle";

export const PageSubTitle = styled(PageTitle)`
  font-size: 18px;
  font-weight: normal;

  @media (min-width: 600px) {
    font-size: 23px;
  }
`;
